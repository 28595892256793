import {
  OperationBody,
  OperationDate,
  OperationFileAttachmentIDs,
  OperationState,
  OperationSubject,
  OperationType,
  OperationClientID,
  OperationMentionedUserID
} from '../../../operationsTypes';

export interface CreateOperationFormData {
  clientId: OperationClientID;
  subject: OperationSubject;
  body: OperationBody;
  date: OperationDate;
  completeDatetime?: OperationDate;
  operationType: OperationType;
  state: OperationState;
  fileAttachmentIds?: OperationFileAttachmentIDs;
  mentionedUserIds?: OperationMentionedUserID[];
  createCompanyRecord?: boolean;
}

export const enum CreateOperationFormFields {
  CREATE_COMPANY_RECORD = 'createCompanyRecord',
  CLIENT_ID = 'clientId'
}

import React, { useMemo, useCallback, useEffect, useState } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import {
  ID,
  ErrorMessage,
  I18nText,
  ClassName,
  IsDisabled
} from '../../../../types';
import { FetchUsersFilters } from '../../usersTypes';

import {
  MultiSelectExtendedDataType,
  MultiSelectGroupedDataType,
  MultiSelectChangeCallbackType,
  MultiSelectInputChangeCallbackType
} from '../../../../helpers/MultiSelect/types';

import { GET_INVITE_USER_SEARCHES_QUERY } from '../../../inviteUserSearches/queries/inviteUserSearches.query';
import { useInviteUserSearches } from '../../../inviteUserSearches/hooks/useInviteUserSearches';

import {
  FETCH_SELECT_FIELD_USERS_QUERY,
  FetchSelectFieldUsersQueryResponse
} from '../../queries/fetchSelectFieldUsers.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useTranslate } from '../../../../common/hooks/useTranslate';

import { usePaginatedUsers } from '../../hooks/usePaginatedUsers';

import { MultiSelect } from '../../../../helpers/MultiSelect';

import { UserCache } from '../../UserCache';

import { words } from '../../../../locales/keys';

const options = {
  staleTime: 1000 * 60 * 60
};

const selectedOptions = {
  staleTime: 1000 * 60 * 60,
  keepPreviousData: true
};

interface UsersMultiSelectProps {
  value: ID[];
  initialFilters?: FetchUsersFilters;
  withoutRecentUsers?: boolean;
  autoFocus?: boolean;
  error?: ErrorMessage;
  i18nPlaceholder?: I18nText;
  inputWrapperClassName?: ClassName;
  i18nLabel?: I18nText;
  labelClassName?: ClassName;
  disabled?: IsDisabled;
  menuPosition?: 'absolute' | 'fixed';
  onChange: (value: ID[]) => void;
}

function UsersMultiSelect({
  value,
  initialFilters,
  withoutRecentUsers,
  autoFocus,
  error,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  labelClassName,
  disabled,
  menuPosition,
  onChange
}: UsersMultiSelectProps) {
  const currentUser = useCurrentUser();

  const { t } = useTranslate();

  const [cachedSelectedData, setCachedSelectedData] = useState<
    MultiSelectExtendedDataType[]
  >([]);

  const {
    inviteUserSearches,
    inviteUserSearchesError,
    inviteUserSearchesFetched
  } = useInviteUserSearches({
    cacheKey: UserCache.allRecentlySelectedUsersCacheKey(),
    query: GET_INVITE_USER_SEARCHES_QUERY,
    initialFilters: {
      userId: { eq: currentUser.id }
    },
    initialLimit: 18,
    options: {
      enabled: !withoutRecentUsers,
      enabledPlaceholder: !withoutRecentUsers
    }
  });

  const { users, usersFetched, usersError, usersFilters, changeUsersFilters } =
    usePaginatedUsers<FetchSelectFieldUsersQueryResponse>({
      cacheKey: UserCache.selectFieldCacheKey(),
      query: FETCH_SELECT_FIELD_USERS_QUERY,
      initialFilters,
      options
    });

  const {
    users: selectedUsers,
    usersError: selectedUsersError,
    usersFilters: selectedUsersFilters,
    usersFetched: selectedUsersFetched,
    changeUsersFilters: changeSelectedUsersFilters
  } = usePaginatedUsers<FetchSelectFieldUsersQueryResponse>({
    cacheKey: UserCache.selectFieldSelectedUsersCacheKey(),
    query: FETCH_SELECT_FIELD_USERS_QUERY,
    initialFilters: {
      id: { in: value }
    },
    initialLimit: 1000,
    options: selectedOptions
  });

  useEffect(() => {
    if (!isEqual(value, selectedUsersFilters?.id?.in)) {
      changeSelectedUsersFilters({ id: { in: value } });
    }
  }, [value, selectedUsersFilters, changeSelectedUsersFilters]);

  const recentUsersData = useMemo<MultiSelectExtendedDataType[]>(() => {
    const recentUsers = compact(
      map(
        inviteUserSearches,
        (inviteUserSearch) => inviteUserSearch.searchedUser
      )
    );
    return recentUsers.map(({ id, fullName, image }) => ({
      value: id,
      label: fullName,
      image: image?.file
    }));
  }, [inviteUserSearches]);

  const data = useMemo<MultiSelectExtendedDataType[]>(() => {
    return users.map(({ id, fullName, image }) => ({
      value: id,
      label: fullName,
      image: image?.file
    }));
  }, [users]);

  const selectedData = useMemo<MultiSelectExtendedDataType[]>(() => {
    return selectedUsers.map(({ id, fullName, image }) => ({
      value: id,
      label: fullName,
      image: image?.file
    }));
  }, [selectedUsers]);

  const multiSelectValue = useMemo<MultiSelectExtendedDataType[]>(() => {
    const dataWithSelectedValues = selectedData
      ? uniqBy(
          concat(selectedData, data, cachedSelectedData, recentUsersData),
          'value'
        )
      : data;

    return isArray(value)
      ? compact(
          value.map((item) => find(dataWithSelectedValues, ['value', item]))
        )
      : [];
  }, [value, data, selectedData, cachedSelectedData, recentUsersData]);

  const recentUsersGroupData = useMemo<MultiSelectGroupedDataType[]>(
    () =>
      isEmpty(recentUsersData)
        ? []
        : [
            {
              label: t(words.recentlySelected),
              options: recentUsersData
            }
          ],
    [recentUsersData, t]
  );

  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (value: MultiSelectExtendedDataType) => {
      if (isArray(value)) {
        setCachedSelectedData(value);
      }
      onChange(isArray(value) ? value.map((item) => item.value) : []);
    },
    [onChange, setCachedSelectedData]
  );

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) => {
      changeUsersFilters(
        { fullName: input },
        compact([input ? null : 'fullName'])
      );
    },
    [changeUsersFilters]
  );

  const showRecentUsers = !withoutRecentUsers && !usersFilters?.fullName;

  return (
    <MultiSelect
      error={
        error ||
        usersError ||
        selectedUsersError ||
        (showRecentUsers ? null : inviteUserSearchesError)
      }
      onChange={handleChange}
      value={multiSelectValue}
      multi={true}
      isSearchable={true}
      optionsLoading={
        showRecentUsers ? !inviteUserSearchesFetched : !usersFetched
      }
      isLoading={
        !selectedUsersFetched && size(value) !== size(multiSelectValue)
      }
      data={showRecentUsers ? recentUsersGroupData : data}
      classNamePrefix="av_multi_select"
      autoFocus={autoFocus}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      i18nLabel={i18nLabel}
      labelClassName={labelClassName}
      disabled={disabled}
      menuPosition={menuPosition}
      onInputChange={handleInputChange}
    />
  );
}

export default UsersMultiSelect;

import {
  ID,
  UUID,
  CreatedAt,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  ErrorMessage,
  IsLoading,
  IsFetched,
  DateType,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsSort,
  UUIDFilter,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsTotalCount,
  NanoIDFilter,
  ItemGqlError,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  IdFilterType,
  FetchItemsFilterItems,
  FetchItemsIsPlaceholderData,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSortItems,
  FetchItemsShowItem,
  TextFilterType,
  BooleanFilter,
  StringFilter,
  DateFilterType
} from '../../types';

import {
  FileAttachmentFile,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentUUID,
  FileAttachmentSize,
  FileAttachmentExt,
  FileAttachmentCreatedAt
} from '../fileAttachments/fileAttachmentsTypes';

import {
  UserUUID,
  UserNanoID,
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserClient,
  UserCurrentTeamNanoID,
  UserID
} from '../users/usersTypes';

import {
  TaskNanoID,
  TaskName,
  TaskProjectNanoID,
  TaskProjectName
} from '../tasks/tasksTypes';

import { ProjectNanoID, ProjectName } from '../projects/projectsTypes';

import {
  TeamNanoID,
  TeamID,
  TeamImageFile,
  TeamName
} from '../teams/teamsTypes';

export type OperationID = ID;
export type OperationUUID = UUID;
export type OperationBody = string;
export type OperationSubject = string;
export type OperationCreatedAt = CreatedAt;
export type OperationCompleteDatetime = DateType;
export type OperationDate = DateType;
export type OperationType = OperationTypes;
export type OperationState = OperationStates;

export type OperationUserUUID = UserUUID;
export type OperationUserNanoID = UserNanoID;
export type OperationUserFullName = UserFullName;
export type OperationUserImageFile = UserImageFile;
export type OperationUserImageUUID = UserImageUUID;
export type OperationUserClient = UserClient;
export type OperationUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type OperationClientID = UserID;
export type OperationTeamNanoID = TeamNanoID;
export type OperationTeamID = TeamID;
export type OperationTeamName = TeamName;
export type OperationTeamImageFile = TeamImageFile;

export type OperationMentionedUserID = UserID;

export type OperationFileAttachmentID = FileAttachmentID;
export type OperationFileAttachmentIDs = OperationFileAttachmentID[];
export type OperationFileAttachmentUUID = FileAttachmentUUID;
export type OperationFileAttachmentName = FileAttachmentName;
export type OperationFileAttachmentFile = FileAttachmentFile;
export type OperationFileAttachmentSize = FileAttachmentSize;
export type OperationFileAttachmentExt = FileAttachmentExt;
export type OperationFileAttachmentCreatedAt = FileAttachmentCreatedAt;

export type OperationTaskNanoID = TaskNanoID;
export type OperationTaskName = TaskName;
export type OperationTaskProjectNanoID = TaskProjectNanoID;
export type OperationTaskProjectName = TaskProjectName;

export type OperationProjectNanoID = ProjectNanoID;
export type OperationProjectName = ProjectName;

export type FetchOperationErrorMessage = ErrorMessage;
export type OperationFetched = IsFetched;
export type OperationLoading = IsLoading;
export type FetchOperationCacheKey = FetchItemsCacheKey;
export type FetchOperationGqlQuery = FetchItemsGqlQuery;

export type FetchOperationsErrorMessage = FetchItemsErrorMessage;
export type FetchOperationsFetched = FetchItemsFetched;
export type FetchOperationsPage = FetchItemsPage;
export type FetchOperationsLimit = FetchItemsLimit;
export type FetchOperationsSort = FetchItemsSort;

export type FetchOperationsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchOperationsPaginateOperations = FetchItemsPaginateItems;
export type FetchOperationsPrefetchOperations = FetchItemsPrefetchItems;
export type FetchOperationsSortOperations = FetchItemsSortItems;
export type FetchOperationsShowOperation = FetchItemsShowItem;

export type FetchOperationsTotalCount = FetchItemsTotalCount;
export type FetchOperationsCacheKey = FetchItemsCacheKey;
export type FetchOperationsGqlQuery = FetchItemsGqlQuery;

export type FetchOperationsCacheKeys = FetchItemsCacheKey[];

export type FetchOperationsFilterOperations =
  FetchItemsFilterItems<FetchOperationsFilters>;

export type CreateOperationGqlQuery = CreateItemGqlQuery;
export type CreateOperationGqlStatus = CreateItemGqlStatus;

export type UpdateOperationGqlQuery = UpdateItemGqlQuery;
export type UpdateOperationGqlStatus = UpdateItemGqlStatus;

export type OperationGqlError = ItemGqlError;

export type FetchOperationsFilters = {
  teamNanoId?: NanoIDFilter;
  userId?: IdFilterType;
  clientId?: IdFilterType;
  userUuid?: UUIDFilter;
  userNanoId?: NanoIDFilter;
  clientNanoId?: NanoIDFilter;
  operationType?: TextFilterType;
  body?: TextFilterType;
  subject?: TextFilterType;
  state?: TextFilterType;
  createdAt?: DateFilterType;
  date?: DateFilterType;
  completeDatetime?: DateFilterType;
  withAttachments?: BooleanFilter;
  projectName?: StringFilter;
  taskName?: StringFilter;
};

export const enum OperationTypes {
  CALL = 'call',
  EVENT = 'event',
  TASK = 'task'
}

export const enum OperationStates {
  PLANNED = 'planned',
  COMPLETED = 'completed',
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  CANCELED = 'canceled',
  WAITING_FOR_INPUT = 'waiting_for_input',
  DEFERRED = 'deferred'
}

export const enum OperationFields {
  SUBJECT = 'subject',
  BODY = 'body',
  DATE = 'date',
  COMPLETE_DATETIME = 'completeDatetime',
  OPERATION_TYPE = 'operationType',
  STATE = 'state',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  CLIENT_ID = 'clientId',
  MENTIONED_USER_IDS = 'mentionedUserIds'
}

export enum FetchOperationsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt',
  DATE = 'date',
  COMPLETE_DATETIME = 'completeDatetime',
  STATE = 'state',
  SUBJECT = 'subject',
  OPERATION_TYPE = 'operationType'
}

export enum FetchOperationsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  UUID_ASC = 'UUID_ASC',
  UUID_DESC = 'UUID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  OPERATION_TYPE_ASC = 'OPERATION_TYPE_ASC',
  OPERATION_TYPE_DESC = 'OPERATION_TYPE_DESC',
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
  COMPLETE_DATETIME_ASC = 'COMPLETE_DATETIME_ASC',
  COMPLETE_DATETIME_DESC = 'COMPLETE_DATETIME_DESC',
  COMPLETE_BODY_ASC = 'COMPLETE_BODY_ASC',
  COMPLETE_BODY_DESC = 'COMPLETE_BODY_DESC',
  STATUS_UPDATES_COUNT_ASC = 'STATUS_UPDATES_COUNT_ASC',
  STATUS_UPDATES_COUNT_DESC = 'STATUS_UPDATES_COUNT_DESC',
  SUBJECT_ASC = 'SUBJECT_ASC',
  SUBJECT_DESC = 'SUBJECT_DESC',
  SOURCE_ASC = 'SOURCE_ASC',
  SOURCE_DESC = 'SOURCE_DESC'
}

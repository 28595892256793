import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { I18nText, IsDisabled } from '../../../../../../../types';

import { CreateOperationTypeFieldButtons } from './components/CreateOperationTypeFieldButtons';

import { Translate } from '../../../../../../../helpers/Translate';

import { OperationTypes } from '../../../../../operationsTypes';

interface CreateOperationTypeFieldProps<FormDataType> {
  name: FieldPath<FormDataType>;
  control: Control<FormDataType>;
  i18nLabel: I18nText;
  disabled?: IsDisabled;
}

function CreateOperationTypeField<FormDataType>({
  control,
  i18nLabel,
  name,
  disabled
}: CreateOperationTypeFieldProps<FormDataType>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            <Translate id={i18nLabel} />
          </label>

          <CreateOperationTypeFieldButtons
            onChange={onChange}
            value={value as OperationTypes}
            disabled={disabled}
          />
        </div>
      )}
    />
  );
}

export default CreateOperationTypeField;

import { gql } from 'graphql-request';

export const CREATE_OPERATION_QUERY = gql`
  mutation CreateOperation(
    $clientId: ID
    $teamNanoId: NanoId
    $subject: String!
    $body: String!
    $date: DateTime!
    $completeDatetime: DateTime
    $operationType: String!
    $state: String!
    $fileAttachmentIds: [ID!]
    $taskId: ID
    $projectId: ID
    $mentionedUserIds: [ID!]
  ) {
    createOperation(
      input: {
        clientId: $clientId
        teamNanoId: $teamNanoId
        subject: $subject
        body: $body
        date: $date
        completeDatetime: $completeDatetime
        operationType: $operationType
        state: $state
        fileAttachmentIds: $fileAttachmentIds
        taskId: $taskId
        projectId: $projectId
        mentionedUserIds: $mentionedUserIds
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;

import React from 'react';
import cl from 'classnames';

import { OperationTypes } from '../../../../../../../operationsTypes';

import { IsDisabled } from '../../../../../../../../../types';

import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';

import { operationsTypes } from '../../../../../../../operationsConstants';

interface CreateOperationTypeFieldButtonsProps {
  value: OperationTypes;
  onChange: (value: OperationTypes) => void;
  disabled?: IsDisabled;
}

function CreateOperationTypeFieldButtons({
  onChange,
  value,
  disabled
}: CreateOperationTypeFieldButtonsProps) {
  return (
    <div className="flex flex-wrap -m-1 mt-1 mb-3">
      {operationsTypes.map((option) => (
        <PureIconButtonHelper
          key={option.value}
          className={cl(
            'rounded-full flex items-center space-x-2 py-1.5 px-4 text-sm font-medium border focus:ring-offset-0 m-0.5',
            value === option.value
              ? 'bg-blue-500 bg-opacity-20 text-blue-600 dark:text-blue-400 border-transparent'
              : 'dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800'
          )}
          disabled={disabled}
          icon={option.icon}
          i18nText={option.i18nLabel}
          i18nTextClassName="truncate"
          onClick={() => onChange(option.value)}
        />
      ))}
    </div>
  );
}

export default CreateOperationTypeFieldButtons;

import React from 'react';
import { Controller } from 'react-hook-form';

import { UserID } from '../../usersTypes';
import { UsersSelectFieldProps } from './UsersSelectField.types';
import { UsersSelect } from '../UsersSelect';

function UsersSelectField<T>({
  control,
  name,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  isClearable,
  labelClassName,
  disabled,
  error: errorMessage,
  initialFilters,
  menuPosition
}: UsersSelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <UsersSelect
          initialFilters={initialFilters}
          onChange={onChange}
          value={value as UserID}
          i18nLabel={i18nLabel}
          disabled={disabled}
          i18nPlaceholder={i18nPlaceholder}
          inputWrapperClassName={inputWrapperClassName}
          isClearable={isClearable}
          labelClassName={labelClassName}
          name={name}
          error={error?.message || errorMessage}
          menuPosition={menuPosition}
        />
      )}
    />
  );
}

export default UsersSelectField;

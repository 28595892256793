import React from 'react';
import { Control, FieldPath } from 'react-hook-form';

import { I18nText, IsDisabled } from '../../../../../../../types';

import { SelectField } from '../../../../../../../helpers/FormFields/SelectField';

import { OperationStates } from '../../../../../operationsTypes';
import { operationsKeys } from '../../../../../../../locales/keys';

const options = [
  {
    value: OperationStates.CANCELED,
    i18nLabel: operationsKeys.statuses.canceled
  },
  {
    value: OperationStates.COMPLETED,
    i18nLabel: operationsKeys.statuses.completed
  },
  {
    value: OperationStates.DEFERRED,
    i18nLabel: operationsKeys.statuses.deferred
  },
  {
    value: OperationStates.IN_PROGRESS,
    i18nLabel: operationsKeys.statuses.in_progress
  },
  {
    value: OperationStates.NOT_STARTED,
    i18nLabel: operationsKeys.statuses.not_started
  },
  {
    value: OperationStates.PLANNED,
    i18nLabel: operationsKeys.statuses.planned
  },
  {
    value: OperationStates.WAITING_FOR_INPUT,
    i18nLabel: operationsKeys.statuses.waiting_for_input
  }
];

interface CreateOperationStateSelectFieldProps<FormDataType> {
  name: FieldPath<FormDataType>;
  control: Control<FormDataType>;
  i18nLabel: I18nText;
  i18nPlaceholder?: I18nText;
  error?: string | null;
  disabled?: IsDisabled;
}

function CreateOperationStateSelectField<FormDataType>({
  control,
  i18nLabel,
  name,
  i18nPlaceholder,
  error,
  disabled
}: CreateOperationStateSelectFieldProps<FormDataType>) {
  return (
    <SelectField
      error={error}
      control={control}
      disabled={disabled}
      i18nLabel={i18nLabel}
      name={name}
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      options={options}
      i18nPlaceholder={i18nPlaceholder}
    />
  );
}

export default CreateOperationStateSelectField;

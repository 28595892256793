import React, { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import cl from 'classnames';
import toUpper from 'lodash/toUpper';
import isString from 'lodash/isString';

import { IconsEnum } from '../../../../../assets/icons/types';

import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';

import { DateTimeFieldControlRequiredProps } from './DateTimeFieldControl.types';

import 'react-datepicker/dist/react-datepicker.css';

interface DateTimeFieldControlProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const dateFormat = 'dd/MM/yyyy';
const timeFormat = 'HH:mm';
const dateTimeFormat = `${dateFormat} ${timeFormat}`;

const popperProps = { strategy: 'fixed' as const };

function DateTimeFieldControl({
  value,
  addInputClassName,
  className,
  disabled,
  error,
  errorClassName,
  id,
  inputClassName,
  inputWrapperClassName,
  inputFieldWrapperClassName,
  label,
  i18nLabel,
  labelClassName,
  minTime,
  maxTime,
  name,
  onChange
}: DateTimeFieldControlProps & DateTimeFieldControlRequiredProps) {
  const handleChange = useCallback<(date: Date | null) => void>(
    (date) => {
      if (date) {
        return onChange(date.toJSON());
      }
      onChange(null);
    },
    [onChange]
  );

  const selectedValue = useMemo<Date | null>(() => {
    if (!value) {
      return null;
    }

    if (isString(value)) {
      const dateFromString = new Date(value);
      return dateFromString.valueOf() ? dateFromString : null;
    }

    return value;
  }, [value]);

  return (
    <div className={className}>
      {i18nLabel || label ? (
        <label
          htmlFor={id || name}
          className={cl(
            labelClassName ||
              'block text-sm font-medium text-gray-700 dark:text-gray-300'
          )}
        >
          {i18nLabel ? <Translate id={i18nLabel} /> : label}
        </label>
      ) : null}
      <div className={inputWrapperClassName}>
        <div className={inputFieldWrapperClassName}>
          <DatePicker
            className={cl(inputClassName || 'basic-input', addInputClassName, {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:focus:ring-red-500 dark:border-red-700 dark:placeholder-red-600 dark:bg-gray-800 dark:text-red-500':
                error
            })}
            disabled={disabled}
            id={id || name}
            name={name}
            onChange={handleChange}
            selected={selectedValue}
            showTimeSelect
            placeholderText={toUpper(dateTimeFormat)}
            dateFormat={dateTimeFormat}
            timeFormat={timeFormat}
            popperProps={popperProps}
            showPopperArrow={false}
            autoComplete="off"
            minTime={minTime}
            maxTime={maxTime}
          />

          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {error && (
              <Icon
                className="h-5 w-5 text-red-500"
                icon={IconsEnum.EXCLAMATION_CIRCLE}
              />
            )}
          </div>
        </div>
      </div>

      {error && (
        <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </div>
  );
}

export default DateTimeFieldControl;

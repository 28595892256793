import * as yup from 'yup';

import { OperationFields } from '../../../../../operationsTypes';
import { CreateOperationFormFields } from '../../CreateOperationForm.types';

import { formsErrors } from '../../../../../../../locales/keys';

export const createOperationFormSchema = yup.object().shape({
  [OperationFields.BODY]: yup.string().required(formsErrors.required),
  [OperationFields.DATE]: yup.string().required(formsErrors.required),
  [OperationFields.SUBJECT]: yup.string().required(formsErrors.required),
  [OperationFields.CLIENT_ID]: yup
    .string()
    .nullable()
    .test(
      'clientIdOrCompany',
      formsErrors.pleaseSelectUserOrCreateCompanyRecord,
      (clientId, ctx) => {
        const createCompanyRecord =
          ctx.parent?.[CreateOperationFormFields.CREATE_COMPANY_RECORD];
        return !!clientId || createCompanyRecord;
      }
    ),
  [CreateOperationFormFields.CREATE_COMPANY_RECORD]: yup
    .bool()
    .nullable()
    .test(
      'clientIdOrCompany',
      formsErrors.pleaseSelectUserOrCreateCompanyRecord,
      (createCompanyRecord, ctx) => {
        const clientId = ctx.parent?.[OperationFields.CLIENT_ID];
        return !!clientId || createCompanyRecord;
      }
    )
});

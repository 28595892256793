import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import { FetchUsersFilters } from '../../../../users/usersTypes';
import { OperationFields, OperationTeamNanoID } from '../../../operationsTypes';
import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../helpers/dropzone/DropzoneHelper';

import { CreateOperationFormData } from './CreateOperationForm.types';

import { CreateOperationStateSelectField } from './components/CreateOperationStateSelectField';
import { CreateOperationTypeField } from './components/CreateOperationTypeField';

import { UsersSelectField } from '../../../../users/helpers/UsersSelectField';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { DateTimeField } from '../../../../../helpers/FormFields/DateTimeField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';
import { ItemsListFieldRequiredProps } from '../../../../../helpers/FormFields/ItemsListField';
import { WorkersMultiselectField } from '../../../../users/helpers/WorkersMultiselectField';

import {
  DropzoneField,
  DropzoneFieldRequiredProps
} from '../../../../../helpers/FormFields/DropzoneField';

import {
  attachmentsKeys,
  operationsKeys,
  usersKeys,
  words
} from '../../../../../locales/keys';

interface CreateOperationFormProps {
  form: string;
  teamNanoId?: OperationTeamNanoID;
  watchCreateCompanyRecord?: boolean;
  isLoading?: IsLoading;
  subjectValidationError?: ErrorMessage;
  bodyValidationError?: ErrorMessage;
  dateValidationError?: ErrorMessage;
  completeDatetimeValidationError?: ErrorMessage;
  clientIdValidationError?: ErrorMessage;
  createCompanyRecordError?: ErrorMessage;
  registerBody: RegisterFormFieldType<HTMLTextAreaElement>;
  registerSubject: RegisterFormFieldType<HTMLInputElement>;
  registerCreateCompanyRecord: RegisterFormFieldType<HTMLInputElement>;
  withUserSelect?: boolean;
  withMentionedUserIds?: boolean;
  userSelectInitialFilters?: FetchUsersFilters;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
}

function CreateOperationForm({
  teamNanoId,
  watchCreateCompanyRecord,
  form,
  control,
  isLoading,
  subjectValidationError,
  bodyValidationError,
  dateValidationError,
  completeDatetimeValidationError,
  clientIdValidationError,
  createCompanyRecordError,
  registerBody,
  registerSubject,
  registerCreateCompanyRecord,
  withUserSelect,
  withMentionedUserIds,
  userSelectInitialFilters,
  initialFileIds,
  initialFiles
}: CreateOperationFormProps &
  ItemsListFieldRequiredProps<CreateOperationFormData> &
  DropzoneFieldRequiredProps<CreateOperationFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="space-y-3">
          <div>
            {withUserSelect && (
              <UsersSelectField<CreateOperationFormData>
                control={control}
                i18nPlaceholder={!watchCreateCompanyRecord && usersKeys.select}
                inputWrapperClassName="relative mt-1"
                name={OperationFields.CLIENT_ID}
                error={clientIdValidationError}
                initialFilters={userSelectInitialFilters}
                disabled={watchCreateCompanyRecord}
                isClearable
              />
            )}

            {teamNanoId ? (
              <div className="mt-1.5">
                <CheckBoxField
                  id="create_record_create_company_record_checkbox"
                  ref={registerCreateCompanyRecord.ref}
                  name={registerCreateCompanyRecord.name}
                  i18nLabel={operationsKeys.createCompanyRecord}
                  disabled={isLoading}
                  error={createCompanyRecordError}
                  onChange={registerCreateCompanyRecord.onChange}
                />
              </div>
            ) : null}
          </div>

          <div>
            <div className="w-full sm:w-auto sm:flex-1">
              <CreateOperationTypeField
                disabled={isLoading}
                i18nLabel={operationsKeys.typeOfActivity}
                name={OperationFields.OPERATION_TYPE}
                control={control}
              />
            </div>
          </div>

          <div>
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                disabled={isLoading}
                error={subjectValidationError}
                i18nLabel={operationsKeys.subject}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerSubject.name}
                onChange={registerSubject.onChange}
                ref={registerSubject.ref}
                type="text"
              />
            </div>
          </div>

          <div>
            <div className="w-full sm:w-auto sm:flex-1">
              <TextAreaField
                disabled={isLoading}
                i18nLabel={operationsKeys.record}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                textAreaWrapperClassName="mt-1 relative"
                name={registerBody.name}
                onChange={registerBody.onChange}
                ref={registerBody.ref}
                rows={5}
                error={bodyValidationError}
              />
            </div>
          </div>

          {withMentionedUserIds ? (
            <div>
              <WorkersMultiselectField<CreateOperationFormData>
                control={control}
                disabled={isLoading}
                name={OperationFields.MENTIONED_USER_IDS}
                i18nLabel={words.notification}
                inputWrapperClassName="mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-3000"
                menuPosition="fixed"
              />
            </div>
          ) : null}

          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <CreateOperationStateSelectField<CreateOperationFormData>
                disabled={isLoading}
                i18nLabel={operationsKeys.status}
                name={OperationFields.STATE}
                control={control}
              />
            </div>
          </div>

          <div>
            <div className="w-full sm:w-auto sm:flex-1">
              <DateTimeField
                control={control}
                disabled={isLoading}
                error={completeDatetimeValidationError}
                i18nLabel={operationsKeys.activityDate}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={OperationFields.COMPLETE_DATETIME}
              />
            </div>
          </div>

          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <DateTimeField
                control={control}
                disabled={isLoading}
                error={dateValidationError}
                i18nLabel={operationsKeys.dueDate}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={OperationFields.DATE}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        <DropzoneField
          control={control}
          name={OperationFields.FILE_ATTACHMENT_IDS}
          type="fileAttachments"
          labelFor="create-operation-add-files"
          i18nLabel={attachmentsKeys.plural}
          disabled={isLoading}
          initialFileIds={initialFileIds}
          initialFiles={initialFiles}
        />
      </div>
    </Form>
  );
}

export default CreateOperationForm;

import {
  CreateOperationGqlQuery,
  CreateOperationGqlStatus,
  FetchOperationsCacheKey,
  OperationBody,
  OperationClientID,
  OperationTeamNanoID,
  OperationCompleteDatetime,
  OperationMentionedUserID,
  OperationDate,
  OperationFileAttachmentIDs,
  OperationGqlError,
  OperationState,
  OperationSubject,
  OperationType,
  OperationUUID
} from '../../operationsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { ProjectID } from '../../../projects/projectsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateOperationOptions {
  query: CreateOperationGqlQuery;
  cacheKeys?: FetchOperationsCacheKey[];
}

interface CreateOperationRecordResponse {
  uuid: OperationUUID;
}

export interface CreateOperationResponse {
  createOperation: {
    status: CreateOperationGqlStatus;
    recordUuid: OperationUUID;
    record: CreateOperationRecordResponse;
    errors: CreateOperationError;
  };
}

export interface CreateOperationInput {
  clientId?: OperationClientID;
  teamNanoId?: OperationTeamNanoID;
  subject: OperationSubject;
  body: OperationBody;
  date: OperationDate;
  operationType: OperationType;
  state: OperationState;
  fileAttachmentIds?: OperationFileAttachmentIDs | null;
  completeDatetime?: OperationCompleteDatetime | null;
  taskId?: TaskID;
  projectId?: ProjectID;
  mentionedUserIds?: OperationMentionedUserID[];
}

export interface CreateOperationError {
  clientId: OperationGqlError;
  teamNanoId: OperationGqlError;
  subject: OperationGqlError;
  body: OperationGqlError;
  date: OperationGqlError;
  operationType: OperationGqlError;
  state: OperationGqlError;
  fileAttachmentIds: OperationGqlError;
  fullMessages: OperationGqlError;
  mentionedUserIds: OperationGqlError;
}

const action = 'createOperation';

function useCreateOperation({ query, cacheKeys }: CreateOperationOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateOperationInput,
    CreateOperationResponse,
    CreateOperationError,
    CreateOperationRecordResponse
  >({ action, cacheKeys, query });

  return {
    createOperationData: createQueryData,
    createOperationError: createQueryError,
    createOperationLoading: createQueryLoading,
    createOperationErrorMessage: createQueryErrorMessage,
    createOperation: createQuery,
    createOperationReset: createQueryReset
  };
}

export default useCreateOperation;

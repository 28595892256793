import React from 'react';
import { Controller, FieldPath, Control } from 'react-hook-form';

import {
  DateTimeFieldControl,
  DateTimeFieldControlRequiredProps
} from './components/DateTimeFieldControl';

interface DateTimeFieldRequiredProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
}

function DateTimeField<T>({
  control,
  addInputClassName,
  className,
  disabled,
  error,
  errorClassName,
  id,
  inputClassName,
  inputWrapperClassName,
  inputFieldWrapperClassName,
  label,
  i18nLabel,
  labelClassName,
  minTime,
  maxTime,
  name
}: DateTimeFieldRequiredProps<T> & DateTimeFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error: fieldError }
      }) => (
        <DateTimeFieldControl
          value={value as string | null}
          addInputClassName={addInputClassName}
          className={className}
          disabled={disabled}
          error={error || fieldError?.message}
          errorClassName={errorClassName}
          id={id}
          inputClassName={inputClassName}
          inputWrapperClassName={inputWrapperClassName}
          inputFieldWrapperClassName={inputFieldWrapperClassName}
          label={label}
          i18nLabel={i18nLabel}
          labelClassName={labelClassName}
          minTime={minTime}
          maxTime={maxTime}
          name={name}
          onChange={onChange}
        />
      )}
    />
  );
}

export default DateTimeField;

import React, { useMemo, useCallback, useEffect, useState } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

import { ID, I18nText, ClassName } from '../../../../types';
import { FetchUsersFilters } from '../../usersTypes';

import {
  MultiSelectExtendedDataType,
  MultiSelectChangeCallbackType,
  MultiSelectInputChangeCallbackType
} from '../../../../helpers/MultiSelect/types';

import {
  FETCH_SELECT_FIELD_USERS_QUERY,
  FetchSelectFieldUsersQueryResponse
} from '../../queries/fetchSelectFieldUsers.query';

import { useUsers } from '../../hooks/useUsers';
import { usePaginatedUsers } from '../../hooks/usePaginatedUsers';

import { MultiSelect } from '../../../../helpers/MultiSelect';

import { UserCache } from '../../UserCache';

const options = {
  staleTime: 1000 * 60 * 60
};

const selectedOptions = {
  staleTime: 1000 * 60 * 60,
  keepPreviousData: true,
  withoutPrefetch: true
};

export interface UsersSelectProps {
  name: string;
  value: ID;
  i18nPlaceholder?: I18nText;
  inputWrapperClassName?: ClassName;
  i18nLabel?: I18nText;
  isClearable?: boolean;
  labelClassName?: ClassName;
  disabled?: boolean;
  error?: string;
  initialFilters?: FetchUsersFilters;
  onChange: (value: ID) => void;
  menuPosition?: 'absolute' | 'fixed';
}

function UsersSelect({
  value,
  name,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  isClearable,
  labelClassName,
  disabled,
  error,
  initialFilters,
  onChange,
  menuPosition
}: UsersSelectProps) {
  const [cachedSelectedData, setCachedSelectedData] = useState<
    MultiSelectExtendedDataType[]
  >([]);

  const {
    users,
    usersError,
    usersLoading,
    changeUsersFilters,
    loadMoreUsers,
    usersFetchingNextPage
  } = useUsers<FetchSelectFieldUsersQueryResponse>({
    cacheKey: UserCache.indexSelectCacheKey(name),
    query: FETCH_SELECT_FIELD_USERS_QUERY,
    initialFilters,
    options
  });

  const {
    users: selectedUsers,
    usersError: selectedUsersError,
    usersLoading: selectedUsersLoading,
    usersFilters: selectedUsersFilters,
    changeUsersFilters: changeSelectedUsersFilters
  } = usePaginatedUsers<FetchSelectFieldUsersQueryResponse>({
    cacheKey: UserCache.selectFieldSelectedUsersCacheKey(),
    query: FETCH_SELECT_FIELD_USERS_QUERY,
    initialFilters: {
      id: { in: [value] }
    },
    initialLimit: 1000,
    options: selectedOptions
  });

  useEffect(() => {
    if (!isEqual(value, selectedUsersFilters?.id?.in)) {
      changeSelectedUsersFilters({ id: { in: value } });
    }
  }, [value, selectedUsersFilters, changeSelectedUsersFilters]);

  const data = useMemo<MultiSelectExtendedDataType[]>(() => {
    return users.map(({ id, fullName, image }) => ({
      value: id,
      label: fullName,
      image: image?.file
    }));
  }, [users]);

  const selectedData = useMemo<MultiSelectExtendedDataType[]>(() => {
    return selectedUsers.map(({ id, fullName, image }) => ({
      value: id,
      label: fullName,
      image: image?.file
    }));
  }, [selectedUsers]);

  const selectValue = useMemo<MultiSelectExtendedDataType>(() => {
    const dataWithSelectedValues = selectedData
      ? uniqBy(concat(selectedData, data, cachedSelectedData), 'value')
      : data;

    return value ? find(dataWithSelectedValues, ['value', value]) : null;
  }, [value, data, selectedData, cachedSelectedData]);

  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (value: MultiSelectExtendedDataType) => {
      if (value) {
        setCachedSelectedData([value]);
      }
      onChange(value?.value || null);
    },
    [onChange, setCachedSelectedData]
  );

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) => {
      changeUsersFilters(
        { fullName: input },
        compact([input ? null : 'fullName'])
      );
    },
    [changeUsersFilters]
  );

  const errorMessage = error || usersError || selectedUsersError;

  return (
    <MultiSelect
      error={errorMessage}
      inputWrapperClassName={inputWrapperClassName}
      onChange={handleChange}
      value={selectValue}
      multi={false}
      isSearchable
      optionsLoading={usersLoading || selectedUsersLoading}
      isLoading={usersFetchingNextPage}
      data={data}
      disabled={disabled}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      isClearable={isClearable}
      labelClassName={labelClassName}
      classNamePrefix={errorMessage ? 'av_error' : 'av'}
      onInputChange={handleInputChange}
      menuPosition={menuPosition}
      onMenuScrollToBottom={loadMoreUsers}
    />
  );
}

export default UsersSelect;

import React from 'react';
import { Controller } from 'react-hook-form';

import { UserIDs } from '../../usersTypes';
import { UsersWithAvatarMultiSelectFieldProps } from './UsersWithAvatarMultiSelectField.types';

import { UsersMultiSelect } from '../UsersMultiSelect';

function UsersWithAvatarMultiSelectField<T>({
  control,
  name,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  labelClassName,
  disabled,
  menuPosition,
  initialFilters
}: UsersWithAvatarMultiSelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <UsersMultiSelect
          value={(value || []) as UserIDs}
          initialFilters={initialFilters}
          withoutRecentUsers
          error={error?.message}
          i18nPlaceholder={i18nPlaceholder}
          inputWrapperClassName={inputWrapperClassName}
          i18nLabel={i18nLabel}
          labelClassName={labelClassName}
          disabled={disabled}
          menuPosition={menuPosition}
          onChange={onChange}
        />
      )}
    />
  );
}

export default UsersWithAvatarMultiSelectField;

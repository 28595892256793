import React from 'react';

import {
  UsersWithAvatarMultiSelectField,
  UsersWithAvatarMultiSelectFieldProps
} from '../UsersWithAvatarMultiSelectField';

const mentorsInitialFilters = {
  withoutBlocked: true,
  financeRoleId: {
    isNotNull: true
  }
};

function WorkersMultiselectField<T>({
  control,
  name,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  labelClassName,
  menuPosition,
  disabled
}: UsersWithAvatarMultiSelectFieldProps<T>) {
  return (
    <UsersWithAvatarMultiSelectField<T>
      initialFilters={mentorsInitialFilters}
      control={control}
      inputWrapperClassName={inputWrapperClassName}
      name={name}
      i18nLabel={i18nLabel}
      disabled={disabled}
      i18nPlaceholder={i18nPlaceholder}
      menuPosition={menuPosition}
      labelClassName={labelClassName}
    />
  );
}

export default WorkersMultiselectField;
